import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #c5b095;
  position: relative;
`

export const MapImage = styled.img`
  width: 100%;
  height: 100%;
`

export const DotElement = styled.div`
  position: absolute;
  left: ${({ x }) => x};
  top: ${({ y }) => y};
  border-radius: 50%;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border: 0.45rem solid #4b433b;
  background-color: #b2936a;
  transition: background-color 0.3s ease-ease-in-out;

  &:hover {
    background-color: #f93737;
  }
`

export const WrapperContent = styled.div`
  padding: 0 0 5rem;
  text-align: left;
  height: 100%;
`

export const WrapperText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const WrapperAudio = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
`
