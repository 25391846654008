const points = [
  {
    content: {
      title: "ogrod saski",
      text: "lorem ipsum1",
    },
    x: "32%",
    y: "13%",
    id: [0, 1],
  },
  {
    content: {
      title: "teatralny",
      text: "lorem ipsum2",
    },
    x: "35%",
    y: "11%",
    id: [2, 3],
  },
  {
    content: {
      title: "wiselka",
      text: "lorem ipsum3",
    },
    x: "64%",
    y: "33%",
    id: [4, 5],
  },
  {
    content: {
      title: "czerniakowska",
      text: "lorem ipsum4",
    },
    x: "59%",
    y: "50%",
    id: [6, 7],
  },
  {
    content: {
      title: "legia",
      text: "lorem ipsum5",
    },
    x: "57%",
    y: "60%",
    id: [8, 9],
  },
  {
    content: {
      title: "sielce",
      text: "lorem ipsum6",
    },
    x: "68%",
    y: "90%",
    id: [13, 14,15,16],
  },
  {
    content: {
      title: "unii lubelskiej",
      text: "lorem ipsum7",
    },
    x: "46%",
    y: "79%",
    id: [10, 11, 12],
  },
]

export default points
