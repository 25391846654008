import React, { useState, useEffect } from "react"
import {
  DotElement,
  MapImage,
  Wrapper,
  WrapperContent,
  WrapperText,
  WrapperAudio,
} from "./styles"
import mapPhoto from "images/mapa.jpg"
import points from "./data"
import Modal from "../../components/Modal/Modal"
import { BodyText, Subtitle } from "../../atoms/typography"
import parse from "html-react-parser"
import { AudioPlayerGroup } from "src/components/AudioPlayer"

const Map = ({ articles }) => {
  const [selectedId, setSelectedId] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (selectedId) {
      const arr = []
      selectedId.forEach(id => arr.push(articles[id]))
      setData(arr)
    }
  }, [selectedId])

  return (
    <Wrapper>
      <MapImage src={mapPhoto} />
      {points.map(({ content, x, y, id }) => (
        <DotElement x={x} y={y} onClick={() => setSelectedId(id)} />
      ))}
      <Modal
        open={data}
        handlerClose={() => {
          setData(null)
        }}
      >
        {data && (
          <div style={{paddingTop: '3rem'}}>
            <Subtitle style={{ paddingBottom: "3rem" }}>
              {data[0].Title}
            </Subtitle>
            <WrapperContent>
              <WrapperText>
                <BodyText>{parse(data[0].Content)}</BodyText>
              </WrapperText>
              <WrapperAudio>
                <AudioPlayerGroup list={data.filter((el,ix)=> ix !==0)} />
              </WrapperAudio>
            </WrapperContent>
          </div>
        )}
      </Modal>
    </Wrapper>
  )
}

export default Map
