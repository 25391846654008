import React, { useState, useEffect } from "react"
import { AudioPlayerGroup } from "src/components/AudioPlayer"
import TitlePage from "src/components/TitlePage"
import Map from "src/components/Map"
import MapMobile from "src/components/MapMobile"
import CustomGrid, { Section, Group } from "src/components/CustomGrid"
import { GRIDS } from "src/util"
import { useArticles } from "src/hooks"
import parse from "html-react-parser"
import { Bookshelf } from "src/atoms"
import { AnimatePresence, motion } from "framer-motion"

const Chapter7 = () => {
  const [isMobile, setIsMobile] = useState(false)
  const articles = useArticles({ articleCategory: 9052 })
  const { list } = articles

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.innerWidth < 1024 && setIsMobile(true)
    }
  }, [])

  if (list.length)
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3.5 }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <TitlePage
              title={list[17]?.Title}
              subtitle={list[17].Lead}
              description={parse(list[17].Content)}
            />
            <CustomGrid>
              <AudioPlayerGroup list={[list[18]]} />
              <div />
            </CustomGrid>
            <div style={{ margin: "10rem 0", textAlign: "center" }}>
              {isMobile ? (
                <MapMobile articles={list.slice(0, 17)} />
              ) : (
                <Map articles={list.slice(0, 17)} />
              )}
            </div>
            <Section>
              <Bookshelf />
              <CustomGrid grid={GRIDS.p17} heightRatio={0.9} custom={true}>
                <Group styles={{ padding: "2rem 1rem" }}>
                  <AudioPlayerGroup
                    list={[list[19], list[20]]}
                    styles={{ marginBottom: "2rem" }}
                  />
                </Group>
                <CustomGrid photos={[list[21]]} grid={GRIDS.p0} />
                <CustomGrid photos={[list[22]]} grid={GRIDS.p0} />
                <CustomGrid photos={[list[23]]} grid={GRIDS.p0} />
                <CustomGrid photos={[list[24]]} grid={GRIDS.p0} />
                <div>
                  <Group styles={{ padding: "2rem 1rem" }}>
                    <AudioPlayerGroup
                      list={[list[25], list[26]]}
                      styles={{ marginBottom: "2rem" }}
                    />
                  </Group>
                </div>
                <CustomGrid photos={[list[27]]} grid={GRIDS.p0} />
                <CustomGrid photos={[list[28]]} grid={GRIDS.p0} />
              </CustomGrid>
            </Section>
          </div>
        </motion.div>
      </AnimatePresence>
    )
  return null
}

export default Chapter7
