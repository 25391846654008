import React from "react"
import SEO from "../components/seo"
import Chapter7 from "src/views/Chapter7"

const IndexPage = () => {
  return (
    <>
      <SEO title="Warszawa-miasto poety" description="Miasto Krzysztofa Kamila Baczyńskiego. Tutaj się urodził, chodził do gimnazjum i liceum. Tutaj poznał miłość – Basię. Tu został żołnierzem i tu zginął. Większość jego adresów ocalała w czasie wojny. W niektórych pozostał ślad jego obecności." image={require('images/seo/7.jpg')}/>
      <Chapter7 />
    </>
  )
}

export default IndexPage
